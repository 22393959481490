
// @ts-nocheck
import { Component, Vue, Watch } from "vue-property-decorator";
import SellerHeader from "@/components/Seller/SellerHeader.vue";
import {
  getTicketsBySellerVip,
  getCategoriesBySeller,
  getProductsBySeller,
  getProductsExcel,
  getProductSellersVIP
} from "@/services/seller.service";
import { isoDateToDay, isoDateToMonth } from "@/helpers/date";
import { Ticket } from "@/interfaces/ticket.interface";
import { ErrorMessages } from "@/helpers/constants";
import { State } from "vuex-class";
import _ from "lodash";

@Component({
  components: {
    SellerHeader
  }
})
export default class Tickets extends Vue {
  //@State(({ sellerState }) => sellerState.eventSelected) eventId!: number;
  eventId!: number = localStorage.getItem("selectedEvent");
  public tickets: Ticket[] = [];
  public ticketsFiltered: Ticket[] = [];
  public productsAutocomplete: any = [];
  public categories: any = [];
  public filter = "";
  public page = 1;
  public totalPages = 1;
  public filterByID = "";
  public filterByCategory = "";
  public search = "";
  public skeletonCount = [0, 1, 2, 3, 4];
  public loading = false;

  setTicketsQuotaColor(constrain: boolean) {
    if (constrain) {
      return "text-color-red";
    }
    return "text-color-blue";
  }


  async exportExcel() {
    const loader = Vue.$loading.show();

    const response = await getProductsExcel(this.eventId)

    loader.hide();

    return response;
  }





  async getTickets(
    page?: number,
    filters: any = {
      reference: "",
      category: false,
      product: false
    }
  ) {
    //const loading = this.$loading.show();
    try {
      this.loading = true;

      const { data, meta } = await getTicketsBySellerVip(
        this.eventId,
        page,
        50,
        filters
      );
      this.tickets = data;
  

      this.totalPages = Math.ceil(meta.total / 50);
      this.ticketsFiltered = _.orderBy(this.tickets, "id", "desc");
      this.loading = false;

      //loading.hide();
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON);
      console.error(error);
      //loading.hide();
    }
  }

  async getCategories() {
    try {
      const data = await getCategoriesBySeller(this.eventId);
      this.categories = data.data.map((category: any) => {
        return {
          value: category.id,
          text: category.name
        };
      });

      localStorage.setItem(
        "ticket-qr-categories-filter",
        JSON.stringify(this.categories)
      );
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON);
    }
  }

  async getProducts() {
    try {
      const data = await getProductsBySeller({
        eventId: this.eventId
      });

      const data2 = await getProductSellersVIP(
        this.eventId
      );

      this.productsAutocomplete = data2.data.data.data.map((product: any) => {
        return {
          value: product.id,
          text: product.name
        };
      });
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON);
    }
  }

  dateToMonth(isoDate: string, type: string) {
    if (type === "day") {
      return isoDateToDay(isoDate);
    }

    return isoDateToMonth(isoDate, true);
  }
  getLinkDetail(uuid: string) {
    this.$router.push({
      name: "Link",
      params: { linkId: uuid }
    });
  }

  searchLinkProduct() {
    this.page = 1;

    this.getTickets(this.page, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID
    });
  }

  searchLinkCategory() {
    this.page = 1;

    this.getTickets(this.page, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID
    });
  }

  searchLink() {
    this.search = this.filter;

    if (this.search.length == 0 || this.search.length > 3) {
      this.page = 1;

      this.getTickets(this.page, {
        reference: this.search,
        category: this.filterByCategory,
        product: this.filterByID
      });
    }
  }

  searchUrlUUID(str: string) {
    const { pathname = null } = new URL(str);
    return pathname && pathname.split("/").find(s => this.isUUID(s));
  }

  isUrl = str => {
    try {
      return Boolean(new URL(str));
    } catch (e) {
      return false;
    }
  };

  isUUID(str: string) {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
  }

  mounted() {
    if (localStorage.getItem("ticket-qr-categories-filter") != null) {
      let data: any = "{}";
      data = localStorage.getItem("ticket-qr-categories-filter");
      this.categories = JSON.parse(data);
    }

    this.getCategories();
    this.getProducts();
    //this.getTickets();
  }

  @Watch("page", { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPageChange(nextPage: number, _currentPage: unknown) {
    this.getTickets(nextPage);
  }
}
